import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";

export type Permission =
  | 'object_create_feature' | 'object_edit_feature' | 'NONE'

export interface PeopleGroupAuthorization {
    id: string,
    permissions: Permission[];
}

export const peopleGroupAuthorizationAdapter: EntityAdapter<PeopleGroupAuthorization> = createEntityAdapter<PeopleGroupAuthorization>();

export const peopleGroupAuthorizationInitialState: EntityState<PeopleGroupAuthorization> = peopleGroupAuthorizationAdapter.getInitialState({
    ids: [],
    entities: {},
});
