import { getRouterSelectors, RouterReducerState } from "@ngrx/router-store";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ROUTER_STATE_KEY } from "../core.state";
import { RouteInfo, RouteStateSnapshot } from "./router.state";

const routeSelectors = getRouterSelectors();
export const {
    selectCurrentRoute, // select the current route
    selectFragment, // select the current route fragment
    selectQueryParams, // select the current route query params
    selectRouteParams, // select the current route params
    selectRouteParam, // factory function to select a route param
    selectRouteData, // select the current route data
    selectUrl, // select the current url
} = routeSelectors;

const {selectQueryParam: selectQueryParamOriginal} = routeSelectors;

export const selectQueryParam = (q: string) => createSelector(selectQueryParamOriginal(q), (result): string | undefined => {
  if (Array.isArray(result)) {
    return result[0];
  }
  return result;
})

export const selectRouterState = createFeatureSelector<RouterReducerState<RouteStateSnapshot> | undefined>(
    ROUTER_STATE_KEY,
);

export const selectParentRouteInfo = createSelector(
    selectRouterState,
    (router: RouterReducerState<RouteStateSnapshot> | undefined) => {
        if (!router) {
            return null;
        }

        return router.state.info.length > 1
            ? router.state.info[router.state.info.length - 2] ?? null
            : null;
    },
);

export const selectCurrentRouteInfo = createSelector(
    selectRouterState,
    (router: RouterReducerState<RouteStateSnapshot> | undefined) => {
        if (!router) {
            return null;
        }

        return router.state.info.length > 0
            ? router.state.info[router.state.info.length - 1] ?? null
            : null;
    },
);

export const selectCurrentRouteUrl = createSelector(
    selectCurrentRouteInfo,
    (info: RouteInfo | null) =>
        info === null ? null : info.routeUrl,
);

export const selectIsCurrentRouteUrlStartsWith = (pattern: string) => createSelector(
    selectCurrentRouteUrl,
    (url: string | null) => !!url && url.startsWith(pattern),
);

export const selectCurrentRouteCommands = createSelector(
    selectCurrentRouteInfo,
    (info: RouteInfo | null) => info ? [info.routeUrl] : [],
);
